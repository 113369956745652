import React from 'react';
import PopinsModalContainer from '../PopinsModal/PopinsModalContainer';

function PopinsSection() {
  return (
    <div data-testid="popins-modal">
      <PopinsModalContainer />
    </div>
  );
}

export default PopinsSection;
