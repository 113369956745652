import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import CheckoutPageContext from '../../context/CheckoutPageContext';
import SwitchTestContext from '../../context/SwitchTestContext';
import RecognizeCustomer from './recognize-customer/recognize-customer';
import StickyBagSummary from './StickyBagSummary/StickyBagSummary';

function CheckoutTopRailPortalComponents({
  isCheckoutStickyNavEnabled,
  useRecognizeCustomer,
  ...props
}) {
  const {
    checkoutPageState,
  } = useContext(CheckoutPageContext);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) { return null; }
  return (
    <div className="checkout-mfe-web-service">
      {checkoutPageState?.bagItems && isCheckoutStickyNavEnabled && <StickyBagSummary {...props} />}
      {useRecognizeCustomer && !checkoutPageState?.userData?.isLoggedIn
      && !checkoutPageState?.userData?.isAppWebView && <RecognizeCustomer />}
    </div>
  );
}

function CheckoutTopRailPortal({ id, ...props }) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const isCheckoutStickyNavEnabled = digitalData && digitalData['chk-sticky-bag-summary'];
  const useRecognizeCustomer = digitalData && digitalData['chk-mfe-recognize-customer'];
  if (typeof document !== 'undefined' && !!document.getElementById(id)) {
    return ReactDOM.createPortal(
      CheckoutTopRailPortalComponents({
        isCheckoutStickyNavEnabled, useRecognizeCustomer, ...props,
      }),
      document.getElementById(id),
    );
  }
  return null;
}

CheckoutTopRailPortal.defaultProps = {
  id: '',
};

CheckoutTopRailPortal.propTypes = {
  id: PropTypes.string,
};

CheckoutTopRailPortalComponents.defaultProps = {
  isCheckoutStickyNavEnabled: false,
  useRecognizeCustomer: false,
};

CheckoutTopRailPortalComponents.propTypes = {
  isCheckoutStickyNavEnabled: PropTypes.bool,
  useRecognizeCustomer: PropTypes.bool,
};

export default CheckoutTopRailPortal;
