import { gql } from '@apollo/client';

export const selectedStoreTextQuery = gql`
  query FetchSelectedStore {
    textFor {
      checkYourPickUpText: pair(pairKey: "checkYourPickUpText") {...values}
      estimatedPickupText: pair(pairKey: "estimatedPickupText") {...values}
      storeHoursText: pair(pairKey: "storeHoursText") {...values}
      today: pair(pairKey: "today") {...values}
      monday: pair(pairKey: "monday") {...values}
      tuesday: pair(pairKey: "tuesday") {...values}
      wednesday: pair(pairKey: "wednesday") {...values}
      thursday: pair(pairKey: "thursday") {...values}
      friday: pair(pairKey: "friday") {...values}
      saturday: pair(pairKey: "saturday") {...values}
      sunday: pair(pairKey: "sunday") {...values}
    }
  }

  fragment values on TextPair {
      key
      value
    }
`;

export default {
  selectedStoreTextQuery,
};
