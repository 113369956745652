import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Disclosure,
} from 'anf-core-react';
import BagList from '../../Common/BagList/BagList';
import OrderTotals from '../../Common/OrderTotals/OrderTotals';

function StickyBagSummary({
  bagItems, tmntData, orderTotals, config, shipMethod, giftCards, vatDetails,
}) {
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  return (
    <div className="sticky-bag-summary" data-testid="sticky-bag-summary">
      {orderTotals && orderTotals?.totalItemsInBag && (
        <>
          <Disclosure
            id="checkout-sticky-bag-summary-disclosure"
            headingPrefix={`${tmntData?.yourBag?.value} ( ${orderTotals?.totalItemsInBag} Items )`}
            label={orderTotals?.grandTotalFmt}
            onClick={() => setOpenModal(!openModal)}
            isExpanded={false}
          />
          <Modal
            id="checkout-sticky-bag-summary-modal"
            isOpen={openModal}
            onClose={handleCloseModal}
            heading={`${tmntData?.yourBag?.value}  ( ${orderTotals?.totalItemsInBag} Items )`}
            closeButtonLabel="Close"
          >
            <div className="sticky-bag-content">
              <div data-testid="sticky-nav-baglist" className="sticky-bag-summary-baglist">
                <BagList
                  variant="rightRail"
                  bagItems={bagItems?.items}
                  tmntData={tmntData}
                />
              </div>

              <OrderTotals
                cartTotalAmountFmt={orderTotals?.cartTotalAmountFmt}
                cartTotalAmount={orderTotals?.cartTotalAmount}
                tmntData={tmntData}
                promoAmountFmt={orderTotals?.promoAmountFmt}
                cartPayableAmount={orderTotals?.grandTotalFmt}
                includesConsumptionTax={
                orderTotals?.includesConsumptionTax
              }
                charityRoundUpFmt={orderTotals?.charityTotalFmt}
                includesGst={orderTotals?.includesGst}
                includesVat={orderTotals?.includesVat}
                showDisclosure={!!config?.showDisclosure}
                isVatIncluded={config?.isVatIncluded}
                totalItemsInBag={orderTotals?.totalItemsInBag}
                shippingHandlingChargeFmt={orderTotals?.shippingHandlingChargeFmt}
                estimatedTaxAmountFmt={orderTotals?.estimatedTaxAmountFmt}
                isCheckoutPage
                shippingModeType={shipMethod?.shipModeType}
                totalGiftBoxesAmount={orderTotals?.totalGiftBoxesAmount}
                totalGiftBoxes={orderTotals?.totalGiftBoxes}
                giftCards={giftCards?.appliedGiftCards}
                displayFreeShippingText={orderTotals?.displayFreeShippingText}
                displayGiftReceiptText={orderTotals?.displayGiftReceiptText}
                vatDetails={vatDetails}
              />
            </div>
          </Modal>
        </>
      )}
    </div>

  );
}

export default StickyBagSummary;
StickyBagSummary.defaultProps = {
  bagItems: {},
  orderTotals: {},
  tmntData: {},
  config: {},
  shipMethod: {},
  giftCards: {},
  vatDetails: {},
};
StickyBagSummary.propTypes = {
  bagItems: PropTypes.instanceOf(Object),
  orderTotals: PropTypes.instanceOf(Object),
  tmntData: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  shipMethod: PropTypes.instanceOf(Object),
  giftCards: PropTypes.instanceOf(Object),
  vatDetails: PropTypes.instanceOf(Object),
};
