import React from 'react';
import PudosModalContainer from '../PudosModal/PudosModalContainer';

function PudosSection() {
  return (
    <div data-testid="pudos-modal">
      <PudosModalContainer />
    </div>
  );
}

export default PudosSection;
