import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from 'anf-core-react';

import MiniBag from '../MiniBag';
import DebugToaster from '../../Common/DebugToaster/DebugToaster';
import Tmnt from '../../Tmnt/Tmnt';
import trackAction from '../../../tools/analytics';
import $window from '../../../tools/window';
import { useIsM } from '../../../hooks/useBreakPoint';
import { GET_MINIBAG_ICON_DATA, GET_MINIBAG_ESSENTIALS } from '../../../gql/miniBagIcon.gql';

export const MINIBAG_TOASTER_ID = 'miniBag-toaster';

const isShoppingBagPage = () => $window.location?.href?.includes('/OrderItemDisplayView');
const isCheckoutPage = () => $window.location?.href?.includes('/OrderCheckoutDisplayView');
const isOCNPage = () => $window.location?.href?.includes('/OrderConfirmationDisplayView');
const isPAYPALPage = () => $window.location?.href?.includes('/ProcessPayPalReturnAndOrderExpress');

const defaultTextFor = {
  itemsInBagTMNT: {
    key: '',
    value: '',
  },
  shoppingBag: {
    key: '',
    value: '',
  },
};

const openToaster = () => {
  if (!isShoppingBagPage() && !isCheckoutPage() && !isOCNPage() && !isPAYPALPage()) {
    const event = new CustomEvent(`${MINIBAG_TOASTER_ID}:open`, { detail: '' });
    window.dispatchEvent(event);
  }
};

const textForPropTypes = PropTypes.shape({
  itemsInBagTMNT: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  shoppingBag: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired;

const switchesPropTypes = PropTypes.shape({
  hasMFEMobileNavToaster: PropTypes.bool.isRequired,
  hasMfeMySavesEnabled: PropTypes.bool.isRequired,
  hasUnifiedCatalogHeaderEnabled: PropTypes.bool.isRequired,
  hasMFELargeScreenFlyoutNav: PropTypes.bool.isRequired,
  hasCatalogMfeFooter: PropTypes.bool.isRequired,
});

const miniBagDataPropTypes = PropTypes.shape({
  textFor: textForPropTypes,
  itemsInBag: PropTypes.number.isRequired,
  shoppingBagUrl: PropTypes.string.isRequired,
  switches: switchesPropTypes.isRequired,
});

function MiniBagIconButton({
  isMobileMiniBag, miniBagData,
}) {
  const isMobileScreen = useIsM();
  const isDesktopMiniBagAndVisible = !isMobileMiniBag && !isMobileScreen;
  const {
    itemsInBag, textFor, shoppingBagUrl, switches,
  } = miniBagData;

  const handleMiniBagClick = () => {
    if (!isDesktopMiniBagAndVisible) {
      return;
    }

    if (isShoppingBagPage()) {
      $window.location.reload();
    } else if (isCheckoutPage() || isPAYPALPage() || isOCNPage()) {
      $window.location.href = shoppingBagUrl;
    } else {
      openToaster();
      trackAction('minibag_click', {
        data_text: 'minibag toaster',
        data_action: 'open',
        event_type: 'click',
      });
    }
  };

  const handleMobileMiniBagClick = () => {
    if (isDesktopMiniBagAndVisible) {
      return;
    }
    $window.location.href = shoppingBagUrl;
    trackAction('universal_click', {
      data_text: 'minibag mobile',
      data_action: 'open',
      event_type: 'click',
    });
  };

  const {
    hasMFEMobileNavToaster,
    hasMfeMySavesEnabled,
    hasUnifiedCatalogHeaderEnabled,
    hasMFELargeScreenFlyoutNav,
    hasCatalogMfeFooter,
  } = switches;

  const isMFEMobileNav = hasMFEMobileNavToaster && hasMfeMySavesEnabled;
  const isMFEDesktopNav = hasUnifiedCatalogHeaderEnabled && hasMFELargeScreenFlyoutNav
        && hasCatalogMfeFooter && hasMfeMySavesEnabled;

  const dataTestId = isMobileMiniBag ? 'minibag-container-mobile' : 'minibag-container-desktop';
  const buttonClass = isMobileMiniBag ? clsx(
    'minibag-nav-smallscreen',
    isMFEMobileNav ? 'minibag-nav-smallscreen-mfe' : 'minibag-nav-smallscreen-legacy',
  ) : clsx(
    'minibag-nav-largescreen',
    isMFEDesktopNav ? '' : 'minibag-nav-largescreen-legacy',
  );
  const onClick = isMobileMiniBag ? handleMobileMiniBagClick : handleMiniBagClick;
  const spanDataTestId = isMobileMiniBag ? 'bag-item-count-mobile' : 'bag-item-count-b-test';

  return (
    <div data-testid={dataTestId}>
      <button
        className={buttonClass}
        data-aui="shopping-bag-nav-link"
        aria-describedby="rs-nav__item-count mini-bag-a11y-desc"
        aria-expanded="false"
        onClick={onClick}
      >
        <Icon
          icon="bag-outline"
          labelText="MiniBag"
          size="m"
        />
        <p className="mini-bag-a11y-desc">
          {textFor && <Tmnt tmnt={textFor?.itemsInBagTMNT} />}
        </p>
        {itemsInBag ? (
          <span
            data-testid={spanDataTestId}
            className={clsx(
              'item-count-badge',
              {
                'has-count': itemsInBag > 0 && !isOCNPage(),
              },
            )}
          >
            {itemsInBag}
          </span>
        ) : null}
        <span className="screen-reader-text">
          {textFor && <Tmnt tmnt={textFor?.shoppingBag} />}
        </span>
      </button>
    </div>
  );
}

MiniBagIconButton.propTypes = {
  isMobileMiniBag: PropTypes.bool.isRequired,
  miniBagData: miniBagDataPropTypes.isRequired,
};

function MiniBagDesktop({
  miniBagData,
}) {
  return (
    <div className="minibag-icon-wrapper">
      <MiniBagIconButton isMobileMiniBag={false} miniBagData={miniBagData} />
      <div data-testid="minibag-under-icon-block" className="minibag-under-icon-block">
        <MiniBag />
      </div>
      <DebugToaster />
    </div>

  );
}

MiniBagDesktop.propTypes = {
  miniBagData: miniBagDataPropTypes.isRequired,
};

function MiniBagMobile({
  miniBagData,
}) {
  return (
    <MiniBagIconButton
      isMobileMiniBag
      miniBagData={miniBagData}
    />
  );
}

MiniBagMobile.propTypes = {
  miniBagData: miniBagDataPropTypes.isRequired,
};

// isMobileMiniBag is a prop to differentiate between mobile and desktop mini bag.
// This is passed from MiniBag Wrapper for Desktop and Mobile.
export default function MiniBagWithIconV2({ isMobileMiniBag }) {
  const isMobileScreen = useIsM();
  // For the visible mini bag, respective variable will be true.
  // And for the other mini bag, both the variables will be false.
  const isDesktopMiniBagAndVisible = !isMobileMiniBag && !isMobileScreen;
  const isMobileMiniBagAndVisible = isMobileMiniBag && isMobileScreen;

  // storeId, textFor, switches and shoppingBagUrl doesn't change
  // during client side render, so we can re-use the cached results
  // from SSR phase. Change in language trigers page reload
  // and the hence this data would be updated during SSR phase.
  const {
    data: minibagEssentials = {},
  } = useQuery(GET_MINIBAG_ESSENTIALS, {
    fetchPolicy: 'cache-first',
    context: { batch: true },
  });

  const {
    storeId,
    textFor = defaultTextFor,
    switches = {
      hasMFEMobileNavToaster: false,
      hasMfeMySavesEnabled: false,
      hasUnifiedCatalogHeaderEnabled: false,
      hasMFELargeScreenFlyoutNav: false,
      hasCatalogMfeFooter: false,
    },
    viewBagButton: { bagUrl: shoppingBagUrl = '' } = {},
  } = minibagEssentials;

  const cacheKey = `af-minicart-${storeId}`;
  const [cookie, setCookie] = useCookies([cacheKey]);
  const itemsInBagFromCookies = Number.isNaN(Number(cookie?.[cacheKey]))
    ? null : Number(cookie?.[cacheKey]);

  // skip the query when items in bag available from cookies
  // or when the respective mini bag is not visible.
  // no-cache is used to fetch the items in bag count on every client
  // side render if not skipped.
  // Currently Responsive Nav bar from CRS is cached for 2 hours and
  // hence we could utilie SSR query results. use the cache-first
  // policy in future when SSR is possible.
  const {
    data: { miniBagIconV2: { itemsInBag = 0, source = null } }
    = { miniBagIconV2: { itemsInBag: 0, source: null } },
    refetch: fetchMiniBagIconData,
  } = useQuery(GET_MINIBAG_ICON_DATA, {
    ssr: false,
    skip: itemsInBagFromCookies !== null
    || (!isDesktopMiniBagAndVisible && !isMobileMiniBagAndVisible),
    fetchPolicy: 'no-cache',
    context: { batch: true },
  });

  const resolvedItemsInBag = itemsInBagFromCookies !== null ? itemsInBagFromCookies : itemsInBag;

  // resolvedItemsInBag will be zero during SSR phase, but will have
  // the actual value from cookies or gql response during CSR phase.
  // hence setting the initial value to zero to avoid hydration errors.
  // and updating with resolvedItemsInBag using useEffec below.
  const [bagItemCount, setBagItemCount] = useState(0);

  useEffect(() => {
    // Update bagItemCount when resolvedItemsInBag changes.
    setBagItemCount(resolvedItemsInBag);
  }, [setBagItemCount, resolvedItemsInBag]);

  // When the cookies are expired or cleared, in GQL
  // the itemsInBag is retrived from the getCart API
  // In this case set the cookie from client, so that
  // subsequent requests will get the itemsInBag from cookie.
  useEffect(() => {
    if (source === 'API' && (isDesktopMiniBagAndVisible || isMobileMiniBagAndVisible)) {
      setCookie(cacheKey, itemsInBag, {
        path: '/',
        maxAge: 60 * 60 * 24, // 1 day
      });
    }
  }, [
    itemsInBag,
    cacheKey,
    source,
    setCookie,
    isDesktopMiniBagAndVisible,
    isMobileMiniBagAndVisible,
  ]);

  const miniBagData = {
    itemsInBag: bagItemCount,
    shoppingBagUrl,
    textFor,
    switches,
  };

  // To listen to the events dispatched when a product is added
  // to or removed from the cart and update bagItemCount.
  useEffect(() => {
    const handleUpdateItemCount = (event) => {
      const items = Array.isArray(event?.detail?.items)
        ? event.detail.items.length : 0;
      setBagItemCount(items);
    };

    const handleProductAddedToCart = () => {
      // We need to query the mini bag icon data only when items in bag
      // is not available in cookies and only for the visible mini bag.
      if (itemsInBagFromCookies === null
        && (isDesktopMiniBagAndVisible || isMobileMiniBagAndVisible)) {
        fetchMiniBagIconData();
      }
      // If the desktop mini bag is visible, then open the toaster.
      if (isDesktopMiniBagAndVisible) {
        openToaster();
      }
    };
    window.addEventListener('miniBag:updateItemCount:done', handleUpdateItemCount);
    window.addEventListener('miniBag:productAddedToCart', handleProductAddedToCart);
    // PDP page dispatches mfe:addToBag:displayMobileNotification event for mobile notification,
    // so we need to listen to it separately
    window.addEventListener('mfe:addToBag:displayMobileNotification', handleProductAddedToCart);
    return function removeListener() {
      window.removeEventListener('miniBag:updateItemCount:done', handleUpdateItemCount);
      window.removeEventListener('miniBag:productAddedToCart', handleProductAddedToCart);
      window.removeEventListener('mfe:addToBag:displayMobileNotification', handleProductAddedToCart);
    };
  }, [
    fetchMiniBagIconData,
    itemsInBagFromCookies,
    isDesktopMiniBagAndVisible,
    isMobileMiniBagAndVisible,
  ]);

  const minibagId = `minibag-with-icon-comp-${isMobileMiniBag ? 'mobile' : 'desktop'}`;
  return (
    <div
      data-testid={minibagId}
      className={minibagId}
    >
      {isMobileMiniBag
        ? <MiniBagMobile miniBagData={miniBagData} />
        : <MiniBagDesktop miniBagData={miniBagData} /> }
    </div>
  );
}

MiniBagWithIconV2.propTypes = {
  isMobileMiniBag: PropTypes.bool,
};

MiniBagWithIconV2.defaultProps = {
  isMobileMiniBag: false,
};
