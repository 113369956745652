import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import Promotions from '../../Common/Promotions/Promotions';
import OrderInStoreCTAs from '../OrderInStore/OrderInStoreCTAs';
import OrderTotals from '../../Common/OrderTotals/OrderTotals';
import GiftBoxAndReceipt from '../GiftBoxAndReceipt/GiftBoxAndReceipt';
import NeedHelp from '../NeedHelp/NeedHelp';
import Footer from '../../Common/Footer/Footer';
import PayOverTime from '../../Common/PayOverTime/PayOverTime';
import FreeShipping from '../../Common/FreeShipping/FreeShipping';
import CheckoutButton from '../../Common/CheckoutButton/CheckoutButton';
import ExpressCheckoutWrapper from '../../Common/ExpressCheckout/ExpressCheckoutWrapper';
import BagContext from '../../../context/BagContext';
import MarketingSpot from '../../Common/MarketingSpot/MarketingSpot';

export default function RightRail({
  shoppingBagCtaRef,
  submitBagMutation,
  updateBagPageState,
}) {
  const {
    bag,
    showDiscount,
    setBag,
    setShowDiscount,
    showNotification,
    setShowNotification,
  } = useContext(BagContext);

  const hasItemsInBag = bag?.bagItems?.items && Object.keys(bag?.bagItems?.items).length !== 0;
  const hasCheckoutButtonCartPut = bag?.switches?.hasCheckoutButtonCartPut;

  return (
    <div className="payment-rail">
      <div className="rail-flex-box">
        <div className="totals-flex-box">
          {/* cta block start */}
          {!bag?.orderInStore?.isSalesAssociateUser
            && (
              <div ref={shoppingBagCtaRef} className="shopping-bag-ctas" data-testid="shopping-bag-ctas">
                {/* Freeshipping progress bar block start */}
                {bag?.freeShippingProgressInfo
                  ?.amountToFreeShippingFmt ? (
                    <FreeShipping
                      isVisible={bag?.freeShippingProgressInfo.isVisible}
                      amountToFreeShipping={bag
                        ?.freeShippingProgressInfo.amountToFreeShipping}
                      tmntData={bag?.textFor}
                      amountToFreeShippingFmt={bag?.freeShippingProgressInfo
                        .amountToFreeShippingFmt}
                      maxProgress={bag?.freeShippingProgressInfo.maxProgress}
                      minProgress={bag?.freeShippingProgressInfo.minProgress}
                      preLabel={bag?.freeShippingProgressInfo.preLabel}
                      postLabel={bag?.freeShippingProgressInfo.postLabel}
                      progressValue={
                      bag?.freeShippingProgressInfo.progressValue
                    }
                    />
                  ) : ''}

                {/* Freeshipping progress bar block end */}
                <CheckoutButton
                  checkoutUrl={bag?.checkoutButton.checkoutUrl}
                  variant={bag?.checkoutButton.variant}
                  tmntData={bag?.textFor}
                  isDisabled={!hasItemsInBag}
                  submitBagMutation={submitBagMutation}
                  hasCheckoutButtonCartPut={hasCheckoutButtonCartPut}
                />
                {(bag?.applePayButton?.hasApplePay || bag?.payPalExpressButton?.hasPayPal) && (
                  <span
                    className="horizontal-or-separator"
                    data-property="GLB_OR"
                  >
                      {bag?.textFor?.orTMNTValue?.value}
                  </span>
                )}
                <ExpressCheckoutWrapper
                  pageContext={bag}
                  updatePageState={updateBagPageState}
                  isDisabled={!hasItemsInBag}
                  page="bag"
                />
              </div>
            )}
          {/* Display Order in Store CTAs */}
          {bag?.orderInStore?.isSalesAssociateUser
            && <OrderInStoreCTAs oisData={bag.orderInStore} />}

          {/* cta block end */}

          {/* promos-loyalty-wrapper start */}
          <div className="promotion-desktop">
            <Promotions
              tmntData={bag?.textFor}
              accordionVariant="bordered"
              showDiscount={showDiscount}
              purchaseContext={bag}
              setPurchaseContext={setBag}
              setShowDiscount={setShowDiscount}
              showNotification={showNotification}
              setShowNotification={setShowNotification}
              page={bag?.page}
            />
          </div>
          {/* promos-loyalty-wrapper end */}
          {/* Start: gift box to display if 'hasGiftBox' or 'hasGiftReceipt' switch is on */}
          {(bag?.switches?.hasGiftBox || bag?.switches?.hasGiftReceipt) && (
          <>
            <GiftBoxAndReceipt />
            <hr />
          </>
          )}
          {/* End: gift box to display if 'hasGiftBox' or 'hasGiftReceipt' switch is on */}
          <OrderTotals
            cartTotalAmountFmt={bag?.orderTotals?.cartTotalAmountFmt}
            tmntData={bag?.textFor}
            promoAmountFmt={bag?.orderTotals?.promoAmountFmt}
            cartPayableAmount={bag?.orderTotals?.subTotalFmt}
            includesConsumptionTax={
              bag?.orderTotals?.includesConsumptionTax
            }
            includesGst={bag?.orderTotals?.includesGst}
            includesVat={bag?.orderTotals?.includesVat}
            showDisclosure={bag?.config?.showDisclosure}
            totalGiftBoxesAmount={bag?.orderTotals?.totalGiftBoxesAmount}
            totalGiftBoxes={bag?.orderTotals?.totalGiftBoxes}
            displayGiftReceiptText={bag?.orderTotals?.displayGiftReceiptText}
          />
          {bag && (
          <PayOverTime
            purchaseAmount={bag?.orderTotals?.subTotal}
            page="bag"
            inline
            hasGiftCard={bag?.bagItems?.hasGiftCard}
          />
          )}
          <NeedHelp
            tmntData={bag?.textFor}
          />
        </div>
      </div>
      <MarketingSpot
        espotId="bag_memberpricing_belowsubtotal"
        espotData={bag?.eSpots?.cartMemberPricingBelowSubtotal}
      />
      <Footer
        orderId={bag?.orderTotals?.orderId}
        tmntData={bag?.textFor}
        phoneNumberByCountry={bag?.footer?.phoneNumberByCountry}
        phoneNumberByLanguage={bag?.footer?.phoneNumberByLanguage}
        isEmptyBag={!hasItemsInBag}
      />
    </div>
  );
}

RightRail.defaultProps = {
  shoppingBagCtaRef: null,
  submitBagMutation: undefined,
};

RightRail.propTypes = {
  shoppingBagCtaRef: PropTypes.instanceOf(Object),
  submitBagMutation: PropTypes.func,
  updateBagPageState: PropTypes.func.isRequired,
};
