import React from 'react';
import PropTypes from 'prop-types';
import PageHeaderError from '../../Common/PageHeader/PageHeaderError';
import ApplePayButton from '../../Common/ApplePayButton/ApplePayButton';
import ApplePayExpress from '../../Common/ApplePayButton/ApplePayExpress';
import ApplePay from '../PaymentManager/ApplePay/ApplePay';
import PaypaylExpressButton from '../../Common/PaypalExpressButton/PaypalExpressButton';
import SignInJoinButton from '../../Common/SignInJoinButton/SignInJoinButton';
import Tmnt from '../../Tmnt/Tmnt';

export default function CheckoutPageHeader({
  tmntData,
  hasItemsInBag,
  hasApplePayPXP,
  loadApplePay,
  applePayData,
  paypalData,
  orderTotals,
  updateCheckoutPageState,
  applePayConfig,
  countriesConfig,
  hasSignInButton,
  hasLoyaltyEnabled,
  isLoggedIn,
}) {
  const PAYPAL_IMAGE_URL = 'https://img.abercrombie.com/is/image/anf/hol-M-20180411-lp-omni-paypal.png';
  return (
    <div className="checkout-page-header">
      <div className="header-wrapper">
        {tmntData?.checkoutButtonText
        && (
        <h1 className="h1 checkout-review-heading" data-testid="checkout-review-heading">
          <Tmnt tmnt={tmntData?.checkoutButtonText} />
        </h1>
        )}
        <div className="button-container" data-testid="button-container">
          {(hasSignInButton && !hasLoyaltyEnabled && !isLoggedIn) && (
          <SignInJoinButton tmntData={tmntData} />
          )}
          {loadApplePay && (
          <ApplePayButton
            orderConfirmationURL={
              applePayData?.orderConfirmationURL
          }
            tmntData={tmntData}
            isDisabled={!hasItemsInBag}
          />
          )}
          {/* Apple Pay PXP should only be added if the switch is On as it loads PXP script */}
          {hasApplePayPXP && (
          <>
            <ApplePay
              applePayConfig={applePayConfig}
              orderTotals={orderTotals}
              isExpressFlow
              updatePageState={updateCheckoutPageState}
              hasApplePayPXP={hasApplePayPXP}
              countriesConfig={countriesConfig}
            />
            <ApplePayExpress
              page="checkout"
              orderConfirmationURL={applePayData?.orderConfirmationURL}
            />
          </>
          )}
          {(paypalData?.hasPayPalExpress || paypalData?.hasPayPal) && (
          <div className="paypal-button" data-testid="paypal-button">
            <PaypaylExpressButton
              paypalExpressURL={
            paypalData?.paypalExpressUrl
          }
              paypalExpressImage={PAYPAL_IMAGE_URL}
              payPalMerchantId={
            paypalData?.payPalEnvironment
          }
              payPalEnvironment={
            paypalData?.payPalMerchantId
          }
              PayPalInContextScriptSrc={
            paypalData?.PayPalInContextScriptSrc
          }
              tmntData={tmntData}
              isDisabled={!hasItemsInBag}
            />
          </div>
          )}
        </div>

      </div>
      <PageHeaderError />
    </div>
  );
}

CheckoutPageHeader.defaultProps = {
  tmntData: {},
  loadApplePay: false,
  hasApplePayPXP: false,
  applePayData: {},
  paypalData: {},
  hasItemsInBag: false,
  orderTotals: {},
  applePayConfig: {},
  countriesConfig: [],
  hasSignInButton: false,
  hasLoyaltyEnabled: false,
  isLoggedIn: false,
};

CheckoutPageHeader.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  loadApplePay: PropTypes.bool,
  hasApplePayPXP: PropTypes.bool,
  hasSignInButton: PropTypes.bool,
  applePayData: PropTypes.instanceOf(Object),
  paypalData: PropTypes.instanceOf(Object),
  hasItemsInBag: PropTypes.bool,
  orderTotals: PropTypes.instanceOf(Object),
  updateCheckoutPageState: PropTypes.func.isRequired,
  applePayConfig: PropTypes.instanceOf(Object),
  countriesConfig: PropTypes.instanceOf(Array),
  hasLoyaltyEnabled: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};
