import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  StoreCard,
  Button,
  InputField,
  Icon,
  SingleEntryForm,
  Logo,
  HorizontalSeparator,
  ErrorMessage,
} from 'anf-core-react';
import Tmnt from '../../Tmnt/Tmnt';
import { useIsS } from '../../../hooks/useBreakPoint';

const textProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const storeProp = PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
  }),
  geoPosition: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  eligibleForPickup: PropTypes.bool.isRequired,
  estimatedPickupDateFmt: PropTypes.string,
  id: PropTypes.string.isRequired,
  mapAndHoursUrl: PropTypes.string,
  staticMapUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  operatingHours: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string.isRequired,
      closeHours: PropTypes.string.isRequired,
      openHours: PropTypes.string.isRequired,
      sortSeq: PropTypes.number.isRequired,
    }),
  ).isRequired,
  storeBrand: PropTypes.string.isRequired,
  storeBrandLogo: PropTypes.string.isRequired,
  storeMessage: PropTypes.string,
});

const defaultProps = {
  defaultSearchValue: undefined,
  popinsResults: undefined,
  searchApiErrorMessage: undefined,
  selectedStore: undefined,
  text: undefined,
};

const propTypes = {
  defaultSearchValue: PropTypes.string,
  isErrorState: PropTypes.bool.isRequired,
  isGeolocationBlocked: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isInputSearching: PropTypes.bool.isRequired,
  isNearMeSearching: PropTypes.bool.isRequired,
  areStoresFound: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInputSearch: PropTypes.func.isRequired,
  onNearMeSearch: PropTypes.func.isRequired,
  onSetSelectedStore: PropTypes.func.isRequired,
  popinsResults: PropTypes.shape({
    popinsLocations: PropTypes.arrayOf(storeProp),
    storeLimit: PropTypes.number.isRequired,
    showAllStores: PropTypes.bool.isRequired,
  }),
  searchApiErrorMessage: PropTypes.string,
  selectedStore: storeProp,
  text: PropTypes.shape({
    cityStateZip: textProp,
    estimatedPickup: textProp,
    findingStoresNear: textProp,
    freeStorePickup: textProp,
    loadMore: textProp,
    locationsNear: textProp,
    mapAndHours: textProp,
    nearMe: textProp,
    noStoresFound: textProp,
    or: textProp,
    pickupUnavailable: textProp,
    pleaseTryDifferentLocation: textProp,
    popinsLegal: textProp,
    popinsResultsError: textProp,
    searching: textProp,
    selectStore: textProp,
    selectedStore: textProp,
    you: textProp,
    locationServicesBlocked: textProp,
    closeButton: textProp,
  }),
};

export default function PopinsModal({
  defaultSearchValue,
  isErrorState,
  isGeolocationBlocked,
  isOpen,
  isInputSearching,
  isNearMeSearching,
  areStoresFound,
  onClose,
  onInputSearch,
  onNearMeSearch,
  onSetSelectedStore,
  popinsResults,
  searchApiErrorMessage,
  selectedStore,
  text,
}) {
  const [searchInputValue, setSearchInputValue] = useState(defaultSearchValue);
  const [searchValueUI, setSearchValueUI] = useState(defaultSearchValue);
  const [storeLimit, setStoreLimit] = useState(popinsResults?.storeLimit);
  const [showAllStores, setShowAllStores] = useState(popinsResults?.showAllStores);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const isMobileView = useIsS();
  const [storeList, setStoreList] = useState(undefined);

  const inputSearch = useCallback(async (e) => {
    e.preventDefault();
    setSearchValueUI(searchInputValue);
    setStoreList(undefined);
    setShowAllStores(false);
    await onInputSearch(searchInputValue);
  }, [onInputSearch, searchInputValue]);

  const nearMeSearch = useCallback(async (e) => {
    e.preventDefault();
    setSearchValueUI(undefined);
    setStoreList(undefined);
    setShowAllStores(false);
    await onNearMeSearch();
  }, [onNearMeSearch]);

  const handleInputChange = useCallback((inputValue) => {
    setSearchInputValue(inputValue);
  }, []);

  const handleSetSelectedStore = useCallback((e, store) => {
    e.preventDefault();
    onSetSelectedStore(store);
  }, [onSetSelectedStore]);

  const loadMore = useCallback(() => {
    setStoreList(popinsResults?.popinsLocations);
    setShowAllStores(true);
  }, [popinsResults]);

  const sortStoreList = useCallback((popinsLocations) => {
    if (popinsLocations && selectedStore) {
      // Find the index of the store with the matching storeNumber
      const storeIndex = popinsLocations.findIndex(
        (store) => store.id === selectedStore.id,
      );
      if (storeIndex >= 0) {
        return [
          popinsLocations[storeIndex],
          ...popinsLocations.filter((store, index) => index !== storeIndex),
        ];
      }
    }
    return popinsLocations;
  }, [selectedStore]);

  const matchStoreListLengthToStoreLimit = useCallback((popinsLocations, limit) => {
    if (!popinsLocations) {
      return popinsLocations;
    }

    return sortStoreList(popinsLocations).slice(0, limit);
  }, [sortStoreList]);

  function createStoreCardChildNode(store) {
    const isSelected = (store.id === selectedStore?.id);
    const iconValue = store.eligibleForPickup ? 'check' : 'close';
    const buttonValue = isSelected ? text?.selectedStore.value : text?.selectStore.value;
    const isBtnDisabled = isSelected || !store.eligibleForPickup;

    return (
      <div className="store-card-content-wrapper">
        <div className="omni-info-text-container">
          <div className={`first-row ${store.eligibleForPickup ? 'can-pickup' : 'cannot-pickup'}`}>
            <Icon
              icon={iconValue}
              labelText={iconValue}
              size="s"
            />
            <p className="omni-info-text">
              {store.eligibleForPickup ? (
                text?.freeStorePickup.value
              ) : (text?.pickupUnavailable.value)}
            </p>
          </div>
          {store.eligibleForPickup && (
            <div className="second-row">
              <p>{`${text?.estimatedPickup.value} ${store.estimatedPickupDateFmt}`}</p>
            </div>
          )}
          {store.storeMessage && (
            <p className="second-row">{store.storeMessage}</p>
          )}
        </div>
        <Button
          classList="select-store-button"
          variant={isSelected ? 'secondary' : 'tertiary-dark'}
          labelText={buttonValue}
          onClick={(e) => handleSetSelectedStore(e, store)}
          isDisabled={isBtnDisabled}
          isFullWidth
        >
          <div>{buttonValue}</div>
        </Button>
      </div>
    );
  }

  function createStoreCards(popinsLocations) {
    return sortStoreList(popinsLocations).map((store, i) => {
      const key = `store-${i}`;
      return (
        <li key={key}>
          <div className="popins-store-card-wrapper">
            <StoreCard
              brand={store.storeBrand}
              storeName={store.name}
              storeMap={(
                <a href={store.mapAndHoursUrl} target="_blank" rel="noreferrer">
                  {text?.mapAndHours.value}
                </a>
              )}
              firstLogo={<Logo kind={store.storeBrandLogo} />}
            >
              {createStoreCardChildNode(store)}
            </StoreCard>
          </div>
        </li>
      );
    });
  }

  const searchInput = useMemo(() => {
    if (text) {
      return (
        <div className="popins-search-input">
          <SingleEntryForm>
            <InputField
              autoComplete="off"
              id="popins-modal-pickup-point-search"
              label={text?.cityStateZip ? text.cityStateZip.value : ''}
              isInvalid={false}
              onChange={(e) => handleInputChange(e.target.value)}
              isRequired
              value={searchInputValue}
            >
              <Button
                classList="popins-search-button"
                variant="secondary"
                onClick={(e) => inputSearch(e)}
                isDisabled={isInputSearching || isNearMeSearching}
                isProcessing={isInputSearching}
              >
                {!isInputSearching && (
                  <Icon
                    icon="search"
                    labelText="search"
                  />
                )}
              </Button>
            </InputField>
          </SingleEntryForm>
        </div>
      );
    }
    return (<div />);
  }, [text, searchInputValue, isInputSearching, isNearMeSearching, handleInputChange, inputSearch]);

  const nearMe = useMemo(() => {
    if (text) {
      return (
        <div className="nearme-wrapper">
          <Button
            classList="nearme-button"
            variant="secondary"
            isFullWidth
            onClick={nearMeSearch}
            isDisabled={isInputSearching || isNearMeSearching || isGeolocationBlocked}
            isProcessing={isNearMeSearching}
          >
            {!isNearMeSearching && (
              <Icon
                icon="location-anf"
                labelText="location"
              />
            )}
            <div
              className="nearme-button-text"
              data-testid="nearme-button-text"
            >
              {text?.nearMe.value}
            </div>
          </Button>
        </div>
      );
    }
    return (<div />);
  }, [text, isGeolocationBlocked, isInputSearching, isNearMeSearching, nearMeSearch]);

  const orSeparator = useMemo(() => {
    if (text) {
      return (
        <div className="horizontal-separator-wrapper">
          <HorizontalSeparator>
            <span>{text?.or.value}</span>
          </HorizontalSeparator>
        </div>
      );
    }
    return (<div />);
  }, [text]);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (popinsResults?.popinsLocations && popinsResults?.storeLimit) {
      setStoreList(
        matchStoreListLengthToStoreLimit(popinsResults.popinsLocations, popinsResults.storeLimit),
      );
      setStoreLimit(popinsResults.storeLimit);
    } else if (!popinsResults?.popinsLocations) {
      setStoreList(null);
    }
  }, [popinsResults, storeLimit, matchStoreListLengthToStoreLimit]);

  useEffect(() => {
    if (defaultSearchValue) {
      setSearchInputValue(defaultSearchValue);
      setSearchValueUI(defaultSearchValue);
    }
  }, [defaultSearchValue]);

  useEffect(() => {
    if (!isOpen) {
      setShowAllStores(false);
    }
  }, [showAllStores, isOpen]);

  if (!text) return null;

  return (
    <Modal
      id="popins-modal"
      isOpen={isModalOpen}
      closeButtonLabel={text?.closeButton.value}
      heading={(
        <h2 className="h2" data-property={text?.selectStore.key} data-testid="popins-modal-header">
          <Tmnt tmnt={text?.selectStore} />
        </h2>
      )}
      onClose={() => {
        // Just need to close the modal without performing any action
        onClose(true);
      }}
    >
      {isMobileView ? (
        <div className="popins-search-wrapper" data-testid="storecard-container">
          {searchInput}
          {orSeparator}
          {nearMe}
        </div>
      ) : (
        <div className="vertical-separator popins-search-wrapper" data-testid="storecard-container">
          {nearMe}
          {searchInput}
        </div>
      )}

      <hr className="popins-modal-hr" />

      <div>
        {(isInputSearching || isNearMeSearching) && (
          <div className="locations-near-text">
            {`${text?.findingStoresNear.value} ${searchValueUI || text?.you.value}`}
          </div>
        )}

        {(popinsResults?.popinsLocations?.length > 0
          && storeList?.length > 0
          && !isInputSearching
          && !isNearMeSearching
        ) && (
          <>
            <div className="locations-near-text">
              {`${popinsResults.popinsLocations.length} ${text?.locationsNear.value} ${searchValueUI || text?.you.value}`}
            </div>
            <ul className="popins-stores-list">
              {createStoreCards(storeList)}
            </ul>
          </>
        )}

        {(!areStoresFound && !isErrorState && !isInputSearching && !isNearMeSearching) && (
          <p className="no-stores" data-testid="no-stores-found">
            {`${text?.noStoresFound.value} ${searchValueUI}. ${text?.pleaseTryDifferentLocation.value}`}
          </p>
        )}

        {(isGeolocationBlocked) && (
          <div className="popins-error" data-testid="geolocation-blocked">
            <ErrorMessage id="popins-modal-error-message">
              <Tmnt tmnt={text?.locationServicesBlocked} />
            </ErrorMessage>
          </div>
        )}

        {(isErrorState && text?.popinsResultsError) && (
          <div className="popins-error">
            <ErrorMessage id="popins-modal-error-message">
              {searchApiErrorMessage ?? <Tmnt tmnt={text?.popinsResultsError} />}
            </ErrorMessage>
          </div>
        )}
      </div>

      <hr className="popins-modal-hr" />
      {(!showAllStores
        && storeList?.length > 0
        && popinsResults?.popinsLocations.length > storeLimit
      ) && (
      <>
        <Button
          classList="link-button load-more-button"
          variant=""
          labelText={text?.loadMore.value}
          onClick={() => loadMore()}
          isDisabled={false}
          isFullWidth
        >
          <div>{text?.loadMore.value}</div>
        </Button>
        <hr className="popins-modal-hr" />
      </>
      )}

      <p className="popins-legal" data-property={text?.popinsLegal.key}>
        <small>{text?.popinsLegal.value}</small>
      </p>

    </Modal>
  );
}
PopinsModal.defaultProps = defaultProps;
PopinsModal.propTypes = propTypes;
