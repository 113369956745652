const MINI_BAG_SWITCHES = [
  'chk-mfe-miniBag-mobile',
  'chk-mini-bag-toaster-delay',
  'chk-display-badge-mini-bag',
  'chk-use-mfe-minibag-with-icon-v2',
  'chk-has-two-x-birthday-notification',
];

const BAG_SWITCHES = [
  'chk-save-for-later',
  'chk-ois-start-session-with-fetch',
  'chk-bag-repudiations-update', // temporary switch for checkout button
  'chk-has-apple-pay-pxp',
  'chk-has-apple-pay',
  'pdp-trigger-quickview-on-load',
  'chk-display-badge-mini-bag',
  'chk-has-two-x-birthday-notification',
];

const CHECKOUT_SWITCHES = [
  'chk-addr-verify-enabled',
  'chk-order-contact-info-mfe-enabled',
  'chk-addr-verify-accept-threshold',
  'chk-google-pay-pxp-script-url',
  'chk-google-pay-config',
  'chk-use-mfe-submit-button',
  'ful-sms-opt-in',
  'chk-hco-only-marketing-checkboxes',
  'ful-delivery-options-tabs',
  'ful-delivery-options-popins-modal',
  'ful-delivery-options-pudos-modal',
  'check-pay-pal-express-parameters-in-url',
  'ful-delivery-options-title',
  'chk-submit-overlay',
  'chk-has-apple-pay-pxp',
  'chk-has-checkout-page-header', // temporary switch for checkout page header to be loaded in checkout page
  'chk-has-apple-pay',
  'chk-use-state-as-country-googlepay',
  'chk-legal-submit-order-copy',
  'chk-sticky-bag-summary',
  'use-mfe-submit-button-for-cod-payment',
  'use-mfe-submit-button-for-apm-payment',
  'chk-has-mfe-submit-button-for-googlepay-payment',
  'chk-mfe-recognize-customer',
  'chk-has-two-x-birthday-notification',
  'google-pay-script-url-override',
  'has-google-pay-2-1-0-script',
  'ful-pudos-selected-store',
  'ful-popins-selected-store',
  'ful-shipping-handling-modal',
];
const OCN_PAGE_SWITCHES = [
  'chk-is-ocn-mfe-enabled',
  'chk-use-mfe-marketing-subscribe',
  'has-order-confirmation-recommendations-enabled', // temporary switch for order confirmation recommendations
];

const ADDRESS_TYPE = {
  popins: 'P',
  pudos: 'CP',
  homeDelivery: 'SB',
};

module.exports = {
  MINI_BAG_SWITCHES,
  BAG_SWITCHES,
  CHECKOUT_SWITCHES,
  OCN_PAGE_SWITCHES,
  ADDRESS_TYPE,
};
