import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import CheckoutPageContext from '../../context/CheckoutPageContext';
import SwitchTestContext from '../../context/SwitchTestContext';
import SelectedStore from '../Common/SelectedStore/SelectedStore';
import $window from '../../tools/window';
import { selectedStoreTextQuery } from '../../gql/selectedStore.gql';

export default function CheckoutSelectedStore({
  parentClass,
}) {
  const [mounted, setMounted] = useState(false);
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const isPudosStoreEnabled = digitalData && digitalData['ful-pudos-selected-store'];
  const isPopinsStoreEnabled = digitalData && digitalData['ful-popins-selected-store'];

  const {
    loading: textLoading,
    error: textError,
    data: textKeys,
  } = useQuery(selectedStoreTextQuery);

  const {
    selectedStore,
    checkoutPageState,
  } = useContext(CheckoutPageContext);

  const selectedShippingSpeed = useMemo(() => checkoutPageState?.shippingSpeed?.find(
    (shippingSpeed) => shippingSpeed.isSelected,
  ), [checkoutPageState.shippingSpeed]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const onChangeStore = () => {
    if (!selectedShippingSpeed) return;
    const modalType = selectedShippingSpeed.shipModeType === 'Popins' ? 'popins-modal' : 'pudos-modal';
    $window.dispatchEvent(new CustomEvent(`${modalType}:open`, {
      detail: {
        selectedStore,
      },
    }));
  };

  if (!mounted
    || !textKeys
    || textLoading
    || textError
    || !selectedStore) {
    return null;
  }

  if (selectedShippingSpeed?.shipModeType === 'Popins' && !isPopinsStoreEnabled) return null;

  if (selectedShippingSpeed?.shipModeType !== 'Popins' && !isPudosStoreEnabled) return null;

  return (
    <div className={`checkout-mfe-web-service ${parentClass}`}>
      <SelectedStore
        storeName={selectedStore.name}
        storeBrand={selectedStore.storeBrand}
        address={selectedStore.address}
        providerType={selectedStore.providerType}
        tmntData={{
          yourPickupLocationTmnt: textKeys?.textFor?.checkYourPickUpText,
          estimatedPickupTmnt: textKeys?.textFor?.estimatedPickupText,
          storeHoursTmnt: textKeys?.textFor?.storeHoursText,
          today: textKeys?.textFor?.today,
          monday: textKeys?.textFor?.monday,
          tuesday: textKeys?.textFor?.tuesday,
          wednesday: textKeys?.textFor?.wednesday,
          thursday: textKeys?.textFor?.thursday,
          friday: textKeys?.textFor?.friday,
          saturday: textKeys?.textFor?.saturday,
          sunday: textKeys?.textFor?.sunday,
        }}
        displayOperatingHours
        storeOperatingHours={selectedStore.operatingHours}
        estimatedPickupDate={selectedStore.estimatedPickupDateFmt}
        mapAndHoursUrl={selectedStore.mapAndHoursUrl}
        staticMapImageUrl={selectedStore.staticMapUrl}
        isCheckoutPage
        onChangeStore={onChangeStore}
        allowChangeStore
        isStore={selectedShippingSpeed?.shipModeType === 'Popins'}
      />
    </div>
  );
}

CheckoutSelectedStore.defaultProps = {
  parentClass: 'pickup-mfe',
};

CheckoutSelectedStore.propTypes = {
  parentClass: PropTypes.string,
};
